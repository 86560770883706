<template>
  <div>
    <b-container v-if="gotData">
      <h1>{{ this.profileData.full_name }}</h1>
      <label><b>Total Mileage: </b></label> <span></span>
      <label
        ><i id="mileage-val">{{ this.profileData.mileage }}</i> miles</label
      ><br />
      <div class="desktop-grid">
        <b-row>
          <b-col cols="lg">
            <TopTractionsCard :traction-list="this.profileData.top_locos">
            </TopTractionsCard>
          </b-col>

          <b-col
            ><LegsPanelVue
              title="Entries"
              :legs="this.profileData.user_legs"
              :syncing="syncing"
              :got-data="this.gotData"
            ></LegsPanelVue
          ></b-col>
        </b-row>
      </div>
    </b-container>
    <b-container v-else
      ><b-icon icon="arrow-repeat" aria-hidden="true" animation="spin"></b-icon
    ></b-container>
  </div>
</template>

<script>
import { fetchWrapper } from "@/helpers/fetchWrapper";
import TopTractionsCard from "@/components/TopTractionsCard.vue";
import LegsPanelVue from "@/components/LegsPanel.vue";

export default {
  name: "UserProfile",
  components: {
    TopTractionsCard,
    LegsPanelVue,
  },
  data() {
    return {
      profileId: this.$route.params.userId,
      profileData: null,
      gotData: false,
    };
  },
  methods: {
    async getProfileInfo() {
      this.gotData = false;
      fetchWrapper
        .get(`${process.env.VUE_APP_API_URL}/user/${this.profileId}`)
        .then((data) => {
          this.profileData = data;
          this.gotData = true;
          //console.log(this.locoData);
        })
        .catch((rej) => {
          //console.log(rej);
          this.error = rej;
        });
    },
  },
  mounted() {
    this.getProfileInfo();
  },
};
</script>
