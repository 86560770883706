import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import LogoutView from "../views/LogoutView.vue";
import DistanceCalcView from "../views/DistanceCalcView.vue";
import RegisterView from "../views/RegisterView.vue";
import LocoInfo from "@/views/LocoInfo";
import UserProfileView from "@/views/UserProfileView.vue";
import TractionPageView from "@/views/TractionPageView.vue";
//import { useAuthStore } from "@/store/auth.store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
  },
  {
    path: "/logout",
    name: "logout",
    component: LogoutView,
  },
  {
    path: "/legs",
    name: "legs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "legs" */ "../views/LegsView.vue"),
  },
  {
    path: "/calc",
    name: "calc",
    component: DistanceCalcView,
  },
  {
    path: "/input",
    name: "input",
    component: () =>
      import(/* webpackChunkName: "input" */ "../views/LegEntryView.vue"),
  },
  {
    path: "/edit/:leg_id",
    name: "edit",
    component: () =>
      import(/* webpackChunkName: "input" */ "../views/LegEntryView.vue"),
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/traction",
    name: "traction",
    component: TractionPageView,
  },
  {
    path: "/traction/:type/:number",
    name: "tractionInfo",
    component: LocoInfo,
  },
  {
    path: "/user/:userId",
    name: "userProfile",
    component: UserProfileView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
