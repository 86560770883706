import { defineStore } from "pinia";

import router from "@/router";
import { fetchWrapper } from "../helpers/fetchWrapper.js";

const baseUrl = `${process.env.VUE_APP_API_URL}`;

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    user: JSON.parse(localStorage.getItem("user")),
    returnUrl: null,
  }),
  actions: {
    async login(username, password) {
      let body = `grant_type=&username=${username}&password=${password}&scope=&client_id=&client_secret=`;

      await fetchWrapper
        .login(`${baseUrl}/token`, {
          body,
        })
        .then((response) => {
          // update pinia state
          this.user = response;

          // store user details and jwt in local storage to keep user logged in between page refreshes
          localStorage.setItem("user", JSON.stringify(response));

          // redirect to previous url or default to home page
          router.push(this.returnUrl || "/");
        })
        .catch((error) => {
          console.log(error);
          return Promise.reject(error);
        });
    },
    async register(username, email, full_name, password, invite_code) {
      //let body = `username=${username}&email=${email}&full_name="${full_name}"&password=${password}&invitation_code=${invite_code}`;
      const params = {
        empty: "",
        email: email,
        user_name: username,
        full_name: full_name,
        password: password,
        invitation_code: invite_code,
        empty2: "",
      };
      let body = new URLSearchParams(params).toString();
      //console.log(body);
      //console.log(params);
      const response = await fetchWrapper.register(`${baseUrl}/register`, {
        body,
      });
      //console.log(response);
      if (response.detail.toLowerCase() == "user registered") {
        router.push("/login");
      } else {
        console.log("Error occurred while registering");
      }
    },
    logout() {
      this.user = null;
      localStorage.removeItem("user");
      localStorage.clear();
      router.push("/login");
    },
    getUser() {
      if (this.user) {
        return this.user;
      }
    },
  },
});
