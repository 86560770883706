<script setup>
import LegCardVue from "./LegCard.vue";
</script>

<template>
  <div class="homepage-panel">
    <div class="homepage-panel-header">
      <h3>{{ this.title }}</h3>
      <b-icon
        class="sync-icon"
        icon="arrow-repeat"
        aria-hidden="true"
        animation="spin"
        v-if="syncing"
      ></b-icon>
    </div>
    <div class="homepage-panel-body">
      <div v-if="gotData">
        <div v-for="(leg, index) in this.$props.legs" :key="index">
          <div
            class="date-horizontal-splitter"
            v-if="isDateDisplayPosition(leg.leg_begin_time, index - 1)"
          >
            <label class="date-horizontal-label">{{
              new Date(leg.leg_begin_time)
                .toISOString()
                .split("T")[0]
                .split("-")[0]
            }}</label>
          </div>
          <LegCardVue
            :card-index="index"
            v-bind:card-number="leg.leg_id"
            v-bind:from="leg.leg_start"
            v-bind:to="leg.leg_end"
            v-bind:start="leg.leg_begin_time"
            :locos="leg.locos"
            :miles="leg.leg_mileage"
            :editMode="false"
            :headcode="leg.leg_headcode"
            :tz="leg.leg_timezone"
            :stripped-mode="true"
          >
          </LegCardVue>
        </div>
      </div>
      <div v-else>
        <span>No data</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    syncing: Boolean,
    legs: Array,
    title: String,
    gotData: Boolean,
  },
  methods: {
    isDateDisplayPosition(date, previous_leg_index) {
      date = new Date(date);
      try {
        const previous_date = new Date(
          this.legs[previous_leg_index].leg_begin_time
        );
        if (
          date.getDate() == previous_date.getDate() &&
          date.getMonth() == previous_date.getMonth() &&
          date.getFullYear() == previous_date.getFullYear()
        ) {
          return false;
        }
      } catch {
        return true;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.sync-icon {
  position: absolute;
  top: 20px;
  left: 25px;
}
.top-locos {
  border: 2px solid grey;
  border-radius: 10px;
}
.top-locos-header {
  background-color: beige;
  border-radius: 10px 10px 0 0;
  margin-bottom: 10px;
}
.top-locos-body {
  padding: 10px;
}

.date-horizontal-splitter {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 2px;
  background-color: var(--background-color-tirtiary);
  margin-top: 25px;
  margin-bottom: 15px;
}
.date-horizontal-label {
  position: relative;
  top: -13px;
  background-color: var(--background-color-tirtiary);
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
}
</style>
