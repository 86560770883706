<script setup>
import LeaderboardUserCard from "@/components/LeaderboardUserCard.vue";
import LegsListPanel from "../Legs/LegsListPanel.vue";
</script>

<template>
  <div v-if="gotData">
    <b-row>
      <b-col cols="lg">
        <div class="homepage-panel">
          <div class="homepage-panel-header"><h3>Stats</h3></div>
          <div class="homepage-panel-body">
            <p>
              <b-link @click.native="goToTractionPage" title="Go to Class"
                >{{ locoData.loco_class }}
              </b-link>
              <i v-if="locoData.loco_name">{{ locoData.loco_name }}</i>
            </p>
            <p v-if="locoData.loco_evn">{{ locoData.loco_evn }}</p>
            <p>{{ locoData.loco_mileage.toFixed(2) }} miles</p>
            <p>{{ locoData.loco_trips }} individual journeys</p>
            <div class="horizontal-divider"></div>
            <p>{{ locoData.loco_notes }}</p>
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="homepage-panel">
          <div class="homepage-panel-header">
            <h3>Mileage Leaderboard</h3>
          </div>
          <div class="homepage-panel-body">
            <LeaderboardUserCard
              v-for="(item, index) in leaderboardList"
              :key="index"
              :leaderboard-index="index"
              :leaderboard-data="item"
            ></LeaderboardUserCard>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="homepage-panel">
          <div class="homepage-panel-header">
            <h3>Trips</h3>
          </div>
          <div class="homepage-panel-body"></div>
          <LegsListPanel
            :legs.sync="this.legs"
            :got-data.sync="this.gotLegsData"
            @refresh="getLegs"
          ></LegsListPanel>
        </div>
      </b-col>
    </b-row>
  </div>
  <b-container v-else
    ><b-icon icon="arrow-repeat" aria-hidden="true" animation="spin"></b-icon>
  </b-container>
</template>

<script>
import { fetchWrapper } from "@/helpers/fetchWrapper";

export default {
  name: "InfoPanel_LocoInfo",

  props: {
    tractionNumber: String,
    tractionType: String,
  },
  data() {
    return {
      gotData: false,
      locoData: {
        loco_id: null,
        loco_type: null,
        loco_number: null,
        loco_name: null,
        loco_class: null,
        loco_operator: null,
        loco_notes: null,
        loco_evn: null,
      },
      leaderboardList: null,
      legs: [],
      leg_offset: 0,
      leg_limit: 20,
      gotLegsData: false,
      tractionPageLink: "",
    };
  },
  methods: {
    async getLocoInformation() {
      this.gotListData = false;
      fetchWrapper
        .get(
          `${process.env.VUE_APP_API_URL}/loco/user/${this.tractionType}/${this.tractionNumber}`
        )
        .then((data) => {
          this.locoData = data[0];
          this.gotData = true;
          this.tractionPageLink = "/traction?class=" + data[0].loco_class;
          //console.log(this.locoData);
        })
        .catch((rej) => {
          //console.log(rej);
          this.error = rej;
        });
    },
    async getLocoLeaderboard() {
      fetchWrapper
        .get(
          `${process.env.VUE_APP_API_URL}/loco/leaderboard/${this.tractionType}/${this.tractionNumber}`
        )
        .then((data) => {
          this.leaderboardList = data;
          //console.log(this.leaderboardList);
        })
        .catch((rej) => {
          //console.log(rej);
          this.error = rej;
        });
    },
    async getLegs() {
      await fetchWrapper
        .get(
          `${process.env.VUE_APP_API_URL}/legs/${this.tractionType}/${this.tractionNumber}?offset=` +
            this.leg_offset +
            "&limit=" +
            this.leg_limit
        )
        .then((data) => {
          this.gotLegsData = false;
          this.legs.push.apply(this.legs, data);
          //console.log(data);
          this.gotLegsData = true;
          this.leg_offset += this.leg_limit;
        });
    },
    goToTractionPage() {
      this.$parent.$parent.$parent.$refs["info-modal"].hide();

      this.$parent.$parent.$parent.$parent.updateClass(
        this.locoData.loco_class
      );
      //this.$parent.$parent.$parent.selectedClass = this.locoData.loco_class;
    },
  },
  beforeMount() {
    this.getLocoInformation();
    this.getLocoLeaderboard();

    this.getLegs();
  },
};
</script>

<style lang="css" scoped>
.homepage-panel {
  height: 98%;
}

.horizontal-divider {
  border-bottom: 1px solid var(--background-color-tirtiary);
  width: 100%;
  margin-bottom: 15px;
}
</style>
