<script setup>
import vSelect from "vue-select";
</script>

<template>
  <b-form-group
    :id="selectGroupId"
    :label="groupLabel"
    :label-for="selectLocationId"
    class="calc-form-group"
  >
    <vSelect
      class="v-select-dropdown"
      :id="selectLocationId"
      :label="label"
      :value="selectedValue"
      :options="paginated"
      :filterable="false"
      @input="updateValue"
      @open="onOpen"
      @close="onClose"
      @search="(query) => (search = query)"
    >
      <template #list-footer
        ><li ref="load" class="loader" v-show="hasNextPage">
          Loading more options...
        </li></template
      ></vSelect
    >
    <b-row
      ><b-col
        ><b-button variant="secondary" title="Move up" @click="move_up"
          ><b-icon
            icon="arrow-up"
            aria-hidden="true"
          ></b-icon></b-button></b-col
      ><b-col
        ><b-button variant="secondary" title="Move down" @click="move_down"
          ><b-icon
            icon="arrow-down"
            aria-hidden="true"
          ></b-icon></b-button></b-col
      ><b-col
        ><b-button variant="danger" @click="deleteRow" title="Delete"
          ><b-icon icon="trash" aria-hidden="true"></b-icon></b-button
      ></b-col> </b-row
  ></b-form-group>
</template>

<script>
const Fuse = require("fuse.js");
export default {
  props: {
    values: Array,
    label: String,
    selectId: Number,
    selectedList: Array,
    initialValue: String,
  },
  data() {
    let selectGroupIdInternal = "group-select-" + this.selectId;
    let selectLocationIdInternal = "location-select-" + this.selectId;
    let labelInternal = "Location #" + this.selectId;

    return {
      observer: null,
      limit: 10,
      search: "",
      selectGroupId: selectGroupIdInternal,
      selectLocationId: selectLocationIdInternal,
      groupLabel: labelInternal,
      selectedValue: this.$props.initialValue,
      offset: 0,
    };
  },
  watch: {
    selectedList: function () {
      this.selectedValue = this.$props.selectedList[this.$props.selectId];
    },
  },
  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    move_down() {
      if (this.$props.selectId + 1 < this.$props.selectedList.length) {
        this.animateMove(
          this.$props.selectedList,
          this.$props.selectId,
          this.$props.selectId + 1
        );
      }
    },
    move_up() {
      if (this.$props.selectId - 1 >= 0) {
        this.animateMove(
          this.$props.selectedList,
          this.$props.selectId,
          this.$props.selectId - 1
        );
        //this.array_move(
        // this.$props.selectedList,
        //this.$props.selectId,
        //this.$props.selectId - 1
        //);
      }
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
    },
    animateMove(arr, index1, index2) {
      const card1 =
        document.getElementById("distance-calc-form").children[index1];
      const card2 =
        document.getElementById("distance-calc-form").children[index2];

      if (card2 == undefined) {
        console.log("No card to swap with");
      }

      const card1Bounds = card1.getBoundingClientRect();
      const card2Bounds = card2.getBoundingClientRect();

      // Translation distances

      const dx = card2Bounds.left - card1Bounds.left;
      const dy = card2Bounds.top - card1Bounds.top;

      card1.style.transform = `translate(${dx}px, ${dy}px)`;
      card2.style.transform = `translate(${-dx}px, ${-dy}px)`;
      card1.style.transition = "all ease 0.35s";
      card2.style.transition = "all ease 0.35s";

      setTimeout(() => {
        card1.style.transform = "";
        card2.style.transform = "";

        card1.style.transition = "";
        card2.style.transition = "";

        this.array_move(arr, index1, index2);
      }, 350);
    },
    updateValue(value) {
      this.$props.selectedList[this.$props.selectId] = value.name;
      this.selectedValue = value;
      this.$emit("updateVal", this.$props.selectedList);
    },
    deleteRow() {
      if (this.$props.selectId > -1) {
        // only splice array when item is found
        this.$props.selectedList.splice(this.$props.selectId, 1); // 2nd parameter means remove one item only
      }
    },
    onSearch(query) {
      this.search = query;
      this.offset = 0;
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
  computed: {
    filtered() {
      if (this.search.toLocaleLowerCase() == "") {
        return this.$props.values;
      }

      //let total_match = this.$props.values.filter((item) =>
      //  item.name.toLocaleLowerCase().includes(this.search.toLocaleLowerCase())
      //);
      //console.log(total_match);
      let options = {
        includeScore: false,
        keys: ["name"],
        threshold: 0.15,
      };
      const fuse = new Fuse(this.$props.values, options);
      const result = fuse.search(this.search.toLocaleLowerCase());
      let ret_array = result.map(({ item }) => item);
      return ret_array;
    },
    paginated() {
      return this.filtered.slice(0, this.limit);
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length;
    },
  },
};
</script>

<style scoped lang="scss">
@import "vue-select/dist/vue-select.css";
.calc-form-group {
  animation: stretch 0.25s cubic-bezier(1, 0, 0, 1);
  background-color: var(--background-color-primary);
  border: 2px solid grey;
  border-radius: 10px;
  padding: 5px;
}
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}
.pagination button {
  flex-grow: 1;
}
.pagination button:hover {
  cursor: pointer;
}
button {
  display: inline-block;
  margin: 5px;
}

@keyframes stretch {
  0%,
  10% {
    transform: scale(1, 0);
  }

  100% {
    transform: scale(1, 1);
  }
}
</style>
