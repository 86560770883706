<template>
  <b-container
    :class="
      traction.loco_operator.toLowerCase() === 'xxx'
        ? 'scrapped traction-card-container'
        : 'traction-card-container'
    "
  >
    <b-row>
      <div id="circle" class="circle" v-if="traction.loco_had"></div>
      <b-col cols="md">{{ traction.loco_class }}</b-col>
      <b-col cols="md"
        ><b>{{ traction.loco_number }}</b></b-col
      >
      <b-col cols="md"
        ><i>{{ traction.loco_name }}</i></b-col
      >
      <b-col cols="md">{{ traction.loco_mileage }} <i>mi</i></b-col>
      <b-col cols="md">{{ traction.loco_journeys }} <i>trips</i></b-col>
      <b-col cols="1"
        ><b-link @click="showInfoModal" title="Loco Information">
          <b-icon
            icon="info-circle"
            aria-hidden="true"
          ></b-icon></b-link></b-col
      ><b-col v-if="elevation" cols="1"
        ><b-link title="Loco Information" @click="showModal">
          <b-icon icon="pencil" aria-hidden="true"></b-icon></b-link
      ></b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    traction: Object,
    elevation: Boolean,
  },
  data() {
    return {
      link: `/traction/${this.traction.loco_type}/${this.traction.loco_number}`,
      scrapped: false,
    };
  },
  methods: {
    showModal() {
      var raiseTractionObject = this.traction;
      raiseTractionObject.add_mode = false;
      this.$emit("onlinkclicked", raiseTractionObject);
    },
    showInfoModal() {
      const infoObj = [this.traction.loco_type, this.traction.loco_number];
      this.$emit("oninfoclicked", infoObj);
    },
    beforeMount() {
      if (this.$props.traction.loco_operator === "XXX") {
        this.scrapped = true;
      }
    },
  },
};
</script>

<style scoped>
.traction-card-container {
  margin: 5px 0 5px 0;
  padding: 5px 15px;
  background: var(--background-color-secondary);
  border: 2px solid var(--background-color-tirtiary);
  overflow: hidden;

  transition: color 0.5s, background 0.5s, border 0.5s;
}

.scrapped {
  background-color: var(--scrapped-background-color);
}

.circle {
  width: 10px;
  height: 10px;
  background: red;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  position: relative;
  left: 10px;
  top: 5px;
}
.had {
  background: linear-gradient(
    -45deg,
    var(--had-primary-color) 50%,
    var(--background-color-tirtiary) 50%
  );
  mask-image: linear-gradient(-45deg, black 50%, black 50%);
  transition: color 0.5s, background 0.5s, border 0.5s;
}
.had a {
  color: var(--link-colour);
}
.had a:hover {
  color: var(--alt-accent-hover);
}
</style>
