<script setup>
import InfoPanel from "@/components/LocoInfo/InfoPanel.vue";
</script>

<template>
  <b-modal
    content-class="modal-background"
    ref="info-modal"
    size="xl"
    hide-footer
  >
    <template #modal-title>{{ tractionNumber }}</template>
    <b-container class="loco-info">
      <InfoPanel
        :traction-number="this.tractionNumber"
        :traction-type="this.tractionType"
      ></InfoPanel>
    </b-container>
  </b-modal>
</template>

<script>
export default {
  name: "LocoInfoView",
  data() {
    return {
      tractionType: null,
      tractionNumber: "",
    };
  },
  methods: {
    showModal(traction) {
      this.tractionType = traction[0];
      this.tractionNumber = traction[1];
      this.$refs["info-modal"].show();
    },
  },
};
</script>

<style scoped>
.update-form-container {
  margin: 15px 0 15px 0;
}
.filter-panel {
  padding: 10px;
  margin: 15px;
  border: 2px solid var(--background-color-tirtiary);
  background-color: var(--background-color-primary);
  transition: color 0.5s, background-color 0.5s;
}
.filter-column {
  margin-bottom: 5px;
}
</style>
