<template>
  <div>
    <b-form @submit="dummySubmit" id="distance-calc-form">
      <loc-select
        v-for="(value, index) in selectedVals"
        :key="index"
        :select-id="index"
        :values="locationsList"
        label="name"
        :selectedList="selectedVals"
        :initial-value="value"
        @updateVal="updateInputValues"
      ></loc-select>
    </b-form>
    <b-row>
      <b-col>
        <b-button
          variant="secondary"
          @click="addNewWaypoint"
          title="Add location"
          ><b-icon icon="plus" aria-hidden="true"></b-icon
        ></b-button>
      </b-col>
      <b-col>
        <b-button variant="primary" @click="calculateRoute"
          >Calculate
          <b-icon
            icon="gear-wide-connected"
            aria-hidden="true"
            animation="spin"
            v-if="showLoading"
          ></b-icon
        ></b-button> </b-col
    ></b-row>
    <b-alert v-model="alertShow" :variant="alertVariant" dismissible
      >{{ this.alertText }}
    </b-alert>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import { useRailStore } from "@/store/rail.store";
import LocationSelect from "@/components/LocationSelect.vue";
import { fetchWrapper } from "@/helpers/fetchWrapper";
let fw = fetchWrapper;
export default {
  props: {
    calculatedRoute: Array,
    cumulativeMiles: Array,
    calculatedMiles: Number,
    inputRoute: Array,
  },
  data() {
    const { locations } = storeToRefs(useRailStore());
    //console.log(locations);
    return {
      locationsList: locations,
      selectedVals: ["", ""],
      showLoading: false,
      alertShow: false,
      alertVariant: "danger",
      alertText: "Error",
    };
  },
  watch: {
    selectedVals: function () {
      localStorage.setItem(
        "distance-calculator-route",
        JSON.stringify(this.selectedVals)
      );
      this.$emit("update:inputRoute", this.selectedVals);
    },
    inputRoute: function () {
      this.selectedVals = this.$props.inputRoute;
    },
    deep: true,
  },
  components: {
    "loc-select": LocationSelect,
  },
  methods: {
    async calculateRoute(event) {
      if (event) {
        event.preventDefault();
      }
      //console.log("Calculatring");
      this.showLoading = true;
      //console.log(this.selectedVals);
      let filteredItems = [];
      localStorage.setItem(
        "distance-calculator-route",
        JSON.stringify(this.selectedVals)
      );
      this.selectedVals.forEach((item) => {
        if (item != "") {
          filteredItems.push(item);
        }
      });
      let submissionBody = {
        route: filteredItems,
      };
      await fw
        .post(`${process.env.VUE_APP_API_URL}/route/distance`, submissionBody)
        .then((data) => {
          //console.log(data);
          if (data.error == true) {
            this.alertShow = true;
            this.alertText = data.error_obj[0].msg[0];
          } else {
            this.alertShow = false;
          }
          this.$emit("update:calculatedRoute", data.calculated_route);
          this.$emit("update:calculatedMiles", data.distance);
          this.$emit("update:cumulativeMiles", data.costs);
          this.$emit("update:inputRoute", data.input_route);
          this.showLoading = false;
        });
    },
    updateInputValues(values) {
      this.$emit("update:inputRoute", values);
      localStorage.setItem(
        "distance-calculator-route",
        JSON.stringify(this.selectedVals)
      );
    },
    addNewWaypoint() {
      this.selectedVals.push("");
    },
    dummySubmit(event) {
      event.preventDefault();
    },
  },
  mounted() {
    if (localStorage.getItem("distance-calculator-route") != null) {
      this.selectedVals = JSON.parse(
        localStorage.getItem("distance-calculator-route")
      );
    }
    useRailStore().updateLocations();
    if (this.$props.inputRoute) {
      this.selectedVals = this.$props.inputRoute;
    }
  },
};
</script>

<style scoped>
button {
  margin: 5px;
}
</style>
