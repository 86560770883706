import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import VueCookies from "vue-cookies";
import VueModal from "@kouts/vue-modal";
import { BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/fonts.css";
import "./assets/colours.css";
import { createPinia, setActivePinia, PiniaVuePlugin } from "pinia";
import { useAuthStore } from "@/store/auth.store";
import "./registerServiceWorker";

import "@kouts/vue-modal/dist/vue-modal.css";
import EditModePanel from "./components/LocoInfo/EditModePanel.vue";
import LocoInfoModal from "./components/LocoInfo/LocoInfoModal.vue";

let pinia = createPinia();
setActivePinia(pinia);
Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.use(PiniaVuePlugin);
Vue.use(BootstrapVueIcons);

Vue.component("VueModal", VueModal);

router.beforeEach(async (to, _, next) => {
  const publicPages = ["/login", "/register"];
  //console.log(to.path);
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();
  //console.log("route");
  if (
    (authRequired && !auth.user) ||
    (auth.user && new Date() > new Date(Date.UTC(auth.getUser().expires)))
  ) {
    //console.log("auth required");
    auth.returnUrl = to.fullPath;
    next({ name: "login" });
  }
  next();
});

new Vue({
  router,
  pinia,
  store,
  render: (h) => h(App),
  components: {
    "edit-mode-panel": EditModePanel,
    "loco-info-modal": LocoInfoModal,
  },
}).$mount("#app");
